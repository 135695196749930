
import React from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import Toolbar from "../../../components/toolbar/Toolbar";
import { Column } from 'primereact/column';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { RouteList } from "./RouteList";
import { actions } from '../route.actions'
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";
import { actions as appActions } from '../../../store/app';
import * as menuActions from '../../../store/menu/menu.actions';
import * as utils from '../../../utils'
import { importFromJSONFile } from '../../../file-handler'

const mapStateToProps = state => {
  const currentMenu = state.menuState.currentRecord;
  const userConfigurations = state.appState.userConfigurations;
  let isFavorite = utils.isFavorite(currentMenu, userConfigurations);
  return {
    columns: state.crudRouteState.columns,
    records: state.crudRouteState.records,
    first: state.crudRouteState.index,
    rows: state.crudRouteState.max,
    totalRecords: state.crudRouteState.count,
    sortField: state.crudRouteState.sortField,
    order: state.crudRouteState.sortOrder,
    menuItems: state.menuState.items,
    currentMenu,
    isFavorite,
    selectedRecords: state.crudRouteState.selectedRecords,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    paginate: paginationParams => dispatch(actions.paginate(paginationParams)),
    sortOrder: sortParams => dispatch(actions.sortOrder(sortParams)),
    applyFilter: filterParams => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams))
    },
    load: () => dispatch(actions.load()),
    userLastAccess: (oidMenu) => dispatch(appActions.userLastAccess(oidMenu)),
    setCurrentMenu: (menuPath) => dispatch(menuActions.setCurrentRecord(menuPath)),
    toggleFavorite: (isFavorite, oidMenu) => {
      dispatch(appActions.toggleFavorite(isFavorite, oidMenu))
    },
    setSelectedRecords: (selectedRecords) => {
      dispatch(actions.setSelectedRecords(selectedRecords))
    },
    exportRoute: () => {
      dispatch(actions.export('rotas'))
    },
    importRoute: (data) => {
      const errorMessageHandler = (results) => {
        const messages = [];
        results.data.forEach(result => {
          const record = result.data;
          if (!result.ok) {
            if (!record.name) {
              messages.push({ message: `Nome da rota em branco.`, type: 'error' }) //TODO to treat the errors
            } else if (!record.oidCarrier) {
              messages.push({ message: `Rota ${record.name} sem transportadora.`, type: 'error' }) //TODO to treat the errors
            } else if (!record.oidOrigin) {
              messages.push({ message: `Rota ${record.name} sem origem.`, type: 'error' }) //TODO to treat the errors
            } else if (!record.oidDestination) {
              messages.push({ message: `Rota ${record.name} sem destino.`, type: 'error' }) //TODO to treat the errors
            } else {
              messages.push({ message: `Falha ao importar a rota ${record.name}. Por favor, contacte o administrador do sistema.`, type: 'error' })
            }
          } else {
            messages.push({ message: `Rota ${record.name} de ${record.originName} para ${record.destinationName}, Transportadora ${record.carrierName}. Importada com sucesso.`, type: 'success' })
          }
        });
        return messages;
      }
      dispatch(actions.import(data, errorMessageHandler))
    },
  }
}


class RouteListPageContainer extends React.Component {

  newHandler = () => {
    this.props.history.push(`${routes.PATH_ROUTE}/novo`)
  }

  componentWillReceiveProps(newProps) {
    if ((!this.props.menuItems || this.props.menuItems.length === 0) && newProps.menuItems && newProps.menuItems.length > 0) {
      const menuPath = this.props.history.location.pathname;
      this.props.setCurrentMenu(menuPath)
    }

    if ((!this.props.currentMenu && newProps.currentMenu) ||
      (this.props.currentMenu !== newProps.currentMenu)) {
      const oidMenu = newProps.currentMenu.oid;
      this.props.userLastAccess(oidMenu)
    }
  }
  componentDidMount() {
    this.props.load();
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    const menuPath = this.props.history.location.pathname;
    this.props.setCurrentMenu(menuPath)
  }
  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      this.props.load();
    }
  }


  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first
    })
  }
  onSort = (e) => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    })


  }
  onFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    console.log(keys)
    keys.forEach(key => {
      if (e.filters.oid) {
        filters.push({
          campo: '_id',
          valor: parseInt(e.filters[key].value),
          tipoFiltro: 'IGUAL_A'
        })
      } else {
        filters.push({
          campo: key,
          valor: e.filters[key].value,
          tipoFiltro: 'CONTEM'
        })
      }
    })
    this.props.applyFilter(filters)
  }

  onRowClick = (e) => {
    const oid = e && e.data && e.data.oid;
    if (oid) {
      this.props.history.push(`${routes.PATH_ROUTE}/${oid}`)
    }
  }
  onFavorite = (e) => {
    if (this.props.currentMenu) {
      const oidMenu = this.props.currentMenu.oid
      const isFavorite = this.props.isFavorite;
      this.props.toggleFavorite(isFavorite, oidMenu)
    }
  }
  onExportRoute = () => {
    this.props.exportRoute()
  }

  onImportRoute = ({ target }) => {
    importFromJSONFile(target.files[0], this.props.importRoute)
  }

  onSelectedRecordsChangeHandler = (e) => {
    this.props.setSelectedRecords(e.value)
  }

  render() {
    const isFavorite = this.props.isFavorite;
    const header = (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h1>Rotas </h1>

          <i className={`pi pi-star lxFavoriteStar ${isFavorite ? 'lxFavorite' : ''}`}
            title={isFavorite ? 'Remover dos favoritos' : 'Adicionar aos favoritos'} onClick={this.onFavorite}></i>
        </div>
        <hr className="lxSplitter" ></hr>
        <div>
          <Toolbar
            onNew={this.newHandler} newButtonStyle='highlighted'
            onExportJSON={this.onExportRoute}
            onImportJSON={this.onImportRoute}
          >
          </Toolbar >
        </div>
      </div>
    );

    let columns = [<Column key="selectorColumn" selectionMode="multiple" style={{ width: '3em' }} />]
    columns = [...columns, ...this.props.columns.map(col => {
      return (<Column {...col} key={col.field} />)
    })]

    const content = <RouteList
      {...this.props}
      onSelectedRecordsChange={this.onSelectedRecordsChangeHandler}
      onRowClick={this.onRowClick}
      onFilter={this.onFilter}
      onPage={this.onPage}
      onSort={this.onSort}
      columns={columns} />;
    return <Page header={header} content={content} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouteListPageContainer);
