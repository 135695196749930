import moment from "moment";
import { Checkbox } from "primereact/checkbox";
import { Menu } from "primereact/menu";
import React from "react";
import { connect } from "react-redux";
import { Page } from "../../../components/page/Page";
import Toolbar from "../../../components/toolbar/Toolbar";
import {
  AppService,
  BUSINESS_UNIT_CHANGE_EVENT_NAME,
  COMPANY_CHANGE_EVENT_NAME,
} from "../../../services/app.service";
import { messageActions } from "../../../store/message";
import { actions } from "../accountConfiguration.actions";
import { AccountConfigurationForm } from "./accountConfigurationForm";


const initialState = {
  favoriteConfigurations: {
    favorites: [],
  },
  freightCalcConfigurations: {
    calculateICMS: false,
    useTransferTable: false,
    extractCubageFromNFeInfoParams: false,
    cubagePattern: "",
    vehicleTypePattern: "",
    cepOriginDefault: "",
    usarTagFiltrarTabelaFrete: false,
    tagFiltrarTabelaEmbarque: "",
    useAddressRebidorByCarrier: []
  },
  auditConfigurations: {
    automatedApprovement: false,
    divergentValuesTolerance: false,
    usePercentValueTolerance: false,
    useFixedValueTolerance: false,
    useAprovationForMenorValue: false,
    percentToleranceValue: 0,
    toleranceValue: 0,
    usePercentageFreightAboutNFe: false,
    percentFreightAboutNFeValue: 0,
    useAprovationForValueCte: false,
    useApprovePostDelivery: false,
    useAuditInvoice: false,
    useDeliveryReceipt: false,
    useSendEmailForClient: false,
    useGroupDocumentsByInvoice: false,
    extractWbsFromNFeInfoParams: false,
    useManualInvoiceRelease: false,
    blockCteNormalNfeInOtherCTE: false,
    enableDivergenceIndicator: false,
  },
  indicatorsConfigurations: {
    efficiency: false,
    divergence: false,
    percentFreightOnBill: false,
    relationWeightByFreight: false,
    freightValueByLocation: false,
  },
  integrationConfigurations: {
    schedulingDelivery: {
      useDeliveryScheduling: false,
    },
    ftpConfiguration: {
      user: "",
      password: "",
      host: "",
      port: ""
    },
    integrationAnymarket: {
      gumgaToken: ""
    },
    orderActionsConfigurations: {
      canUpdate: true,
      canDelete: true,
    },
    shipmentActionsConfigurations: {
      canUpdate: true,
      canDelete: true,
    },
    roteirizador: {
      lognex: false,
      agile: false
    },
    extractVendedorFromNFeInfo: false,
    filterNFeByUserPerfil: false,
    onlyNFeWithEDI: false,
  },
  deliveryDeadLineConfigurations: {
    before: 0,
    after: 0,
  },
  orderManagementConfigurations: {
    deliveryTime: "",
    workingDay: {
      workingMorning: { start: moment().startOf("D").toDate(), end: moment().endOf("D").toDate() },
      workingAfternoon: { start: moment().startOf("D").toDate(), end: moment().endOf("D").toDate() },
    },
    workDays: {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    },
  },

  favoritesToStay: [],

  docaVehicleOperation: [],
  customColumns: [],
}
function findMenu(oidMenu, menuItems) {
  let menuFinded = null;
  menuItems.forEach((menuItem) => {
    if (menuItem.oid === oidMenu) {
      menuFinded = menuItem;
    }
    if (menuItem.items) {
      const resultChildren = findMenu(oidMenu, menuItem.items);
      if (resultChildren) {
        menuFinded = resultChildren;
      }
    }
  });
  return menuFinded;
}

function isChildMenu(oidMenu, menuItems) {
  let isChild = false;
  menuItems.forEach((menuItem) => {
    if (menuItem.oid === oidMenu) {
      isChild = true;
      return;
    }
    if (menuItem.items && isChildMenu(oidMenu, menuItem.items)) {
      isChild = true;
      return;
    }
  });
  return isChild;
}


const menuItems = [
  { label: "Favoritos", url: "#favoritos" },
  { label: "Cálculo de Frete", url: "#calculofrete" },
  { label: "Auditoria", url: "#auditoria" },
  { label: "Indicadores", url: "#indicators" },
  { label: "Gestão de Embarque", url: "#gestaoembarque" },
  { label: "Configuração Unidade", url: "#unitconfiguration" },
  { label: "Integrações", url: "#integrations" },
];

const mapStateToProps = (state) => {
  const { userConfigurations = {} } = state.appState;
  const { currentRecord = {} } = state.crudAccountConfigurationState;
  const { freightCalculationConfiguration = {}, auditConfiguration = {}, businessUnitConfigurations = [], docaVehicleOperation = [], customColumns = [], selectedOccurrenceTypeForApp = [] } = currentRecord;
  let { orderManagementConfigurations = {} } = currentRecord;
  let { deliveryDeadLineConfigurations = {} } = currentRecord;
  let favoritesFromUserConfigurations = userConfigurations.favorites ? userConfigurations.favorites : [];
  favoritesFromUserConfigurations = favoritesFromUserConfigurations.map(favorite => { return { oid: favorite } })
  let favorites = [...initialState.favoriteConfigurations.favorites, ...favoritesFromUserConfigurations]
  favorites = favorites.map(favorite => {
    const menu = findMenu(favorite.oid, state.menuState.items);
    if (menu) {
      return {
        oid: menu.oid,
        title: menu.label,
        selected: true
      }
    }
    return favorite;
  })
  const indicatorsConfigurations = { ...initialState.indicatorsConfigurations, ...userConfigurations.indicators };
  const favoriteConfigurations = { favorites };
  const freightCalcConfigurations = { ...initialState.freightCalcConfigurations, ...freightCalculationConfiguration };
  const auditConfigurations = { ...initialState.auditConfigurations, ...auditConfiguration };
  const integrationConfigurations = { ...initialState.integrationConfigurations, ...currentRecord.integrationConfigurations };
  orderManagementConfigurations = { ...initialState.orderManagementConfigurations, ...orderManagementConfigurations };
  deliveryDeadLineConfigurations = { ...initialState.deliveryDeadLineConfigurations, ...deliveryDeadLineConfigurations };
  const user = userConfigurations.user;
  const favoritesToStay = initialState.favoritesToStay;
  const currentCompany = state.appState.currentCompany;

  return {
    user,
    configurations: {
      favoriteConfigurations,
      freightCalcConfigurations,
      auditConfigurations,
      indicatorsConfigurations,
      integrationConfigurations,
      deliveryDeadLineConfigurations,
      orderManagementConfigurations,
      favoritesToStay,
      currentCompany,
      businessUnitConfigurations,
      docaVehicleOperation,
      customColumns,
      selectedOccurrenceTypeForApp,
    }
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendNotification: (type, message) => {
      const messages = [message];
      const makeMessages = (messages, type) => {
        return messages.map((message) => {
          return { sticky: true, severity: type, detail: message };
        });
      };
      dispatch(messageActions.messageShowMessages(makeMessages(messages, type)));
    },
    loadAccountConfigurationsByCompany: (cnpj) => {
      dispatch(actions.loadAccountConfigurationsByCompany(cnpj));
    },

    saveAccountConfigurations: (e, data) => {
      dispatch(actions.saveAccountConfigurations(data));
    },

  };
};

class AccountConfigurationFormPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState }

  }

  configureStateFromProps = (props) => {
    const state = { ...props.configurations }
    this.setState((prevState) => {
      return state;
    });
  }

  componentDidMount() {
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    const { currentCompany = {} } = this.props;
    const { CNPJ = "" } = currentCompany
    this.props.loadAccountConfigurationsByCompany(CNPJ)
    this.configureStateFromProps(this.props);
  }
  componentWillReceiveProps(nextProps) {
    this.configureStateFromProps(nextProps);
  }

  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications(eventName, cnpj) {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      this.props.loadAccountConfigurationsByCompany(cnpj)
    }
  }

  freightCalculationChangeHandler = (event) => {
    const target = event.target;
    const field = target.name;
    let value = target.value;
    const updateValues = (field, value) => {
      this.setState((prevState) => {
        const freightCalcConfigurations = { ...prevState.freightCalcConfigurations, [field]: value };
        return {
          freightCalcConfigurations
        }
      })
    }
    if (field === 'extractCubageFromNFeInfoParams' && !value) {
      updateValues('cubagePattern', '');
    }
    if (field === 'extractVehicleTypeFromNFeInfoParams' && !value) {
      updateValues('vehicleTypePattern', '');
    }
    if (field === 'usarTagFiltrarTabelaFrete' && !value) {
      updateValues('tagFiltrarTabelaEmbarque', '');
    }

    updateValues(field, value);

  }

  onDeliveryDeadlineChangeHandler = (event) => {
    const target = event.target;
    const field = target.name;
    let value = parseInt(target.value);
    // console.log(" [field]: ",[field], " value => ",value)
    this.setState((prevState) => {
      const deliveryDeadLineConfigurations = { ...prevState.deliveryDeadLineConfigurations, [field]: value };
      // console.log(" deliveryDeadLine => ", deliveryDeadLineConfigurations)
      return {
        deliveryDeadLineConfigurations
      }
    });
  }

  indicatorsChangeHandler = (event) => {
    const indicators = this.state.indicatorsConfigurations;
    const keys = Object.keys(indicators);
    let total = 0;
    keys.forEach((key) => {
      if (indicators[key]) {
        total++;
      }
    });

    const target = event.target;
    const field = target.name;
    let value = target.value;
    if (!value || total < 4) {
      this.setState((prevState) => {
        const indicatorsConfigurations = { ...prevState.indicatorsConfigurations, [field]: value };
        return {
          indicatorsConfigurations
        }
      })

    } else {
      this.props.sendNotification(
        "error",
        "Você só pode ter 4 indicadores habilitados"
      );
    }
  };

  auditChangeHandler = (event) => {
    const target = event.target;
    const field = target.name;
    let value = target.value;

    this.setState((prevState) => {

      if (field === "usePercentValueTolerance" && value === false) {
        prevState.auditConfigurations.percentToleranceValue = 0;
      }

      if (field === "useFixedValueTolerance" && value === false) {
        prevState.auditConfigurations.toleranceValue = 0;
      }

      if (field === "usePercentageFreightAboutNFe" && value === false) {
        prevState.auditConfigurations.percentFreightAboutNFeValue = 0;
      }
      const auditConfigurations = { ...prevState.auditConfigurations, [field]: value };
      return {
        auditConfigurations
      }
    })
  }

  integrationsChangeHandler = (event) => {
    const target = event.target;
    const field = target.name;
    const id = target.id;
    let value = target.value;
    this.setState((prevState) => {
      let integrationConfigurations = { ...prevState.integrationConfigurations }
      if (field === "useDeliveryScheduling") {
        let schedulingDelivery = { ...integrationConfigurations.shedulingDelivery, [field]: value }
        integrationConfigurations = { ...integrationConfigurations, schedulingDelivery }
      } else if (id === 'order.canUpdate' || id === 'order.canDelete') {
        let orderActionsConfigurations = { ...integrationConfigurations.orderActionsConfigurations, [field]: value }
        integrationConfigurations = { ...integrationConfigurations, orderActionsConfigurations }
      } else if (id === 'shipment.canUpdate' || id === 'shipment.canDelete') {
        let shipmentActionsConfigurations = { ...integrationConfigurations.shipmentActionsConfigurations, [field]: value }
        integrationConfigurations = { ...integrationConfigurations, shipmentActionsConfigurations }
      } else if (id === "onlyNFeWithEDI") {
        integrationConfigurations.onlyNFeWithEDI = value
      } else if (id === "extractVendedorFromNFeInfo") {
        integrationConfigurations.extractVendedorFromNFeInfo = value
      } else if (id === "filterNFeByUserPerfil") {
        integrationConfigurations.filterNFeByUserPerfil = value
      } else {
        let ftpConfiguration = { ...integrationConfigurations.ftpConfiguration, [field]: value }
        integrationConfigurations = { ...integrationConfigurations, ftpConfiguration }
      }
      return {
        integrationConfigurations
      }
    })
  };

  integrationsDocaManagerChangeHandler = (event) => {
    const target = event.target;
    const field = target.name;
    let value = target.value;

    this.setState((prevState) => {
      let integrationConfigurations = { ...prevState.integrationConfigurations }
      integrationConfigurations = { ...integrationConfigurations, [field]: value }
      return {
        integrationConfigurations
      }
    })
  };

  integrationsAnymarketChangeHandler = (event) => {
    const target = event.target;
    const field = target.name;
    let value = target.value;

    this.setState((prevState) => {
      let integrationConfigurations = { ...prevState.integrationConfigurations }
      let integrationAnymarket = { ...integrationConfigurations.integrationAnymarket, [field]: value }
      integrationConfigurations = { ...integrationConfigurations, integrationAnymarket }
      return {
        integrationConfigurations
      }
    })
  };

  integrationRouterChangeHandler = (event) => {
    const target = event.target;
    const field = target.name;
    let value = target.value;

    this.setState((prevState) => {
      let integrationConfigurations = { ...prevState.integrationConfigurations }
      let roteirizador = { ...integrationConfigurations.roteirizador, [field]: value }
      if (field == "lognex")
        roteirizador.agile = false;
      if (field == "agile")
        roteirizador.lognex = false;
      integrationConfigurations = { ...integrationConfigurations, roteirizador }
      return {
        integrationConfigurations
      }
    })
  };


  onFavoriteChangeHandler = (event) => {
    const value = event.value;
    const checked = event.checked;
    this.setState((prevState) => {
      const favorites = prevState.favoriteConfigurations.favorites.map((favorite) => {
        if (favorite.oid == value) {
          favorite.selected = checked
        }
        return { ...favorite }
      })
      return {
        favoriteConfigurations: {
          favorites
        }
      }
    })
  }


  hasAuditChanges = () => {
    let hasChanges = false;
    const auditState = this.state.auditConfigurations || {};
    const auditProps = this.props.configurations.auditConfigurations || {};
    const keysState = Object.keys(auditState);
    for (const key of keysState) {
      if (auditState[key] != auditProps[key]) {
        hasChanges = true;
        break
      }
    }
    return hasChanges;
  }

  hasFreightCalculationChanges = () => {
    const freightCalcState = this.state.freightCalcConfigurations || {};
    const freightCalcProps = this.props.configurations.freightCalcConfigurations || {};
    const keysState = Object.keys(freightCalcState);
    let hasChanges = false;
    for (const key of keysState) {
      if (freightCalcState[key] != freightCalcProps[key]) {
        hasChanges = true;
        break
      }
    }
    return hasChanges;

  }

  saveAccountConfigurations = (e, reprocess) => {
    this.state.favoriteConfigurations.favorites.forEach((favorite) => {
      if (favorite.selected && this.state.favoritesToStay.indexOf(favorite.oid) == -1) {
        this.state.favoritesToStay.push(favorite.oid);
      }
    });

    const data = {
      user: this.props.user,
      record: {
        favoriteConfigurations: this.state.favoritesToStay || [],
        freightCalcConfigurations: this.state.freightCalcConfigurations || {},
        auditConfigurations: this.state.auditConfigurations || {},
        indicatorsConfigurations: this.state.indicatorsConfigurations || {},
        integrationConfigurations: this.state.integrationConfigurations || {},
        orderManagementConfigurations: this.state.orderManagementConfigurations || {},
        deliveryDeadLineConfigurations: this.state.deliveryDeadLineConfigurations || {},
        businessUnitConfigurations: this.state.businessUnitConfigurations || [],
        docaVehicleOperation: this.state.docaVehicleOperation || [],
        customColumns: this.state.customColumns || [],
        selectedOccurrenceTypeForApp: this.state.selectedOccurrenceTypeForApp || [],
      },
      reprocess: !!reprocess,
    }
    this.props.saveAccountConfigurations(e, data);
  }


  orderManagementOnChange = (e) => {
    const target = e.target;
    let field = target.name;
    let value = target.value;


    if (field === "deliveryTime") {
      value = parseInt(value)
    }

    if (field === "workingDay.workingMorning.start") {
      value = moment(value, "HH:mm:ss").toDate();
    }

    if (field === "workingDay.workingMorning.end") {
      value = moment(value, "HH:mm:ss").toDate();
    }

    if (field === "workingDay.workingAfternoon.start") {
      value = moment(value, "HH:mm:ss").toDate();
    }

    if (field === "workingDay.workingAfternoon.end") {
      value = moment(value, "HH:mm:ss").toDate();
    }

    let fields = field.split(".");

    this.setState((prevState) => {
      let orderManagementConfigurations = { ...prevState.orderManagementConfigurations }
      let obj = orderManagementConfigurations;
      if (fields.length > 1) {
        for (let index = 0; index < fields.length - 1; index++) {
          obj = obj[fields[index]]
        }
      }
      obj[fields[fields.length - 1]] = value
      return {
        orderManagementConfigurations
      }
    })

  }

  render() {
    const {
      favoriteConfigurations,
      freightCalcConfigurations,
      indicatorsConfigurations,
      auditConfigurations,
      integrationConfigurations,
      orderManagementConfigurations,
      deliveryDeadLineConfigurations,
    } = this.state;


    const { schedulingDelivery = { useDeliveryScheduling: false },
      orderActionsConfigurations = { canUpdate: true, canDelete: true },
      shipmentActionsConfigurations = { canUpdate: true, canDelete: true },
      ftpConfiguration,
      integrationAnymarket = { gumgaToken: "" }, roteirizador = { lognex: false, agile: true } } = integrationConfigurations;

    const configurations = {
      favoriteConfigurations: {
        favorites: favoriteConfigurations.favorites
      },
      freightCalcConfigurations: [
        {
          id: "calculateICMS",
          componentType: 'CHECKBOX',
          data: {
            title: "Calcular ICMS",
            value: freightCalcConfigurations.calculateICMS,
            onChange: this.freightCalculationChangeHandler,
            name: "calculateICMS",
            id: "calculateICMS",
          },
        },
        {
          id: "useTransferTable",
          componentType: 'CHECKBOX',
          data: {
            title: "Usa tabelas de transferência",
            value: freightCalcConfigurations.useTransferTable,
            onChange: this.freightCalculationChangeHandler,
            name: "useTransferTable",
            id: "useTransferTable",
          },
        },
        {
          id: "extractCubageFromNFeInfoParams",
          componentType: 'CHECKBOX',
          data: {
            title: "Extrair cubagem das inf. adicionais da NF",
            value: freightCalcConfigurations.extractCubageFromNFeInfoParams,
            onChange: this.freightCalculationChangeHandler,
            name: "extractCubageFromNFeInfoParams",
            id: "extractCubageFromNFeInfoParams",
          },
        },
        {
          id: "extractVehicleTypeFromNFeInfoParams",
          componentType: 'CHECKBOX',
          data: {
            title: "Extrair tipo veículo das inf. adicionais da NF",
            value: freightCalcConfigurations.extractVehicleTypeFromNFeInfoParams,
            onChange: this.freightCalculationChangeHandler,
            name: "extractVehicleTypeFromNFeInfoParams",
            id: "extractVehicleTypeFromNFeInfoParams",
          },
        }, {
          id: "usarTagFiltrarTabelaFrete",
          componentType: 'CHECKBOX',
          data: {
            title: "Usar TAG filtrar tabela de fretes Embarques",
            value: freightCalcConfigurations.usarTagFiltrarTabelaFrete,
            onChange: this.freightCalculationChangeHandler,
            name: "usarTagFiltrarTabelaFrete",
            id: "usarTagFiltrarTabelaFrete",
          },
        }
        ,
        {
          id: "cepOriginDefault",
          componentType: 'TEXT',
          data: {
            title: "CEP Padrão",
            value: freightCalcConfigurations.cepOriginDefault,
            onChange: this.freightCalculationChangeHandler,
            name: "cepOriginDefault",
            id: "cepOriginDefault",
          }
        },

      ],
      indicatorConfigurations: [
        {
          id: "efficiency",
          componentType: 'CHECKBOX',
          data: {
            title: "Eficiência de Entrega",
            value: indicatorsConfigurations.efficiency,
            onChange: this.indicatorsChangeHandler,
            name: "efficiency",
            id: "efficiency",
          },
        },
        {
          id: "divergence",
          componentType: 'CHECKBOX',
          data: {
            title: "Valor Divergente de Frete",
            value: indicatorsConfigurations.divergence,
            onChange: this.indicatorsChangeHandler,
            name: "divergence",
            id: "divergence",
          },
        },
        {
          id: "percentFreightOnBill",
          componentType: 'CHECKBOX',
          data: {
            title: "Porcentagem do Frete por NFe",
            value: indicatorsConfigurations.percentFreightOnBill,
            onChange: this.indicatorsChangeHandler,
            name: "percentFreightOnBill",
            id: "percentFreightOnBill",
          },
        },
        {
          id: "relationWeightByFreight",
          componentType: 'CHECKBOX',
          data: {
            title: "Valor do Frete por Kg",
            value: indicatorsConfigurations.relationWeightByFreight,
            onChange: this.indicatorsChangeHandler,
            name: "relationWeightByFreight",
            id: "relationWeightByFreight",
          },
        },
        {
          id: "freightValueByLocation",
          componentType: 'CHECKBOX',
          data: {
            title: "Valor do Frete por Estado",
            value: indicatorsConfigurations.freightValueByLocation,
            onChange: this.indicatorsChangeHandler,
            name: "freightValueByLocation",
            id: "freightValueByLocation",
          },
        },
      ],
      auditConfigurations: [
        {
          id: "automatedApprovement",
          componentType: 'CHECKBOX',
          data: {
            title: "Usar aprovação automática",
            value: auditConfigurations.automatedApprovement,
            onChange: this.auditChangeHandler,
            name: "automatedApprovement",
            id: "automatedApprovement",
          },
        },
        {
          id: "useAuditInvoice",
          componentType: 'CHECKBOX',
          data: {
            title: "Realizar auditoria por Fatura",
            value: auditConfigurations.useAuditInvoice,
            onChange: this.auditChangeHandler,
            name: "useAuditInvoice",
            id: "useAuditInvoice",
          },
        },
        {
          id: "useSendEmailForClient",
          componentType: 'CHECKBOX',
          data: {
            title: "Enviar email para o cliente",
            value: auditConfigurations.useSendEmailForClient,
            onChange: this.auditChangeHandler,
            name: "useSendEmailForClient",
            id: "useSendEmailForClient",
          }
        },
        {
          id: "useDeliveryReceipt",
          componentType: 'CHECKBOX',
          data: {
            title: "Realiza envio de comprovante de entrega",
            value: auditConfigurations.useDeliveryReceipt,
            onChange: this.auditChangeHandler,
            name: "useDeliveryReceipt",
            id: "useDeliveryReceipt",
          },
        },
        {
          id: "useApprovePostDelivery",
          componentType: 'CHECKBOX',
          data: {
            title: "Aprovar CTE após entrega",
            value: auditConfigurations.useApprovePostDelivery,
            onChange: this.auditChangeHandler,
            name: "useApprovePostDelivery",
            id: "useApprovePostDelivery",
          },
        },
        {
          id: "useAprovationForMenorValue",
          componentType: 'CHECKBOX',
          data: {
            title: "Aprovar caso REALIZADO menor que ORÇADO",
            value: auditConfigurations.useAprovationForMenorValue,
            onChange: this.auditChangeHandler,
            name: "useAprovationForMenorValue",
            id: "useAprovationForMenorValue",
          },
        },
        {
          id: "divergentValuesTolerance",
          componentType: 'CHECKBOX',
          data: {
            title: "Aplicar tolerância em valores divergentes",
            value: auditConfigurations.divergentValuesTolerance,
            onChange: this.auditChangeHandler,
            name: "divergentValuesTolerance",
            id: "divergentValuesTolerance",
          },
        },
        {
          id: "useAprovationForValueCte",
          componentType: 'CHECKBOX',
          data: {
            title: "Aprovar CTE por valor",
            value: auditConfigurations.useAprovationForValueCte,
            onChange: this.auditChangeHandler,
            name: "useAprovationForValueCte",
            id: "useAprovationForValueCte",
          }
        },
        {
          id: "usePercentValueTolerance",
          componentType: 'CHECKBOX',
          data: {
            title: "Utilizar valor percentual de tolerância",
            value: auditConfigurations.usePercentValueTolerance,
            onChange: this.auditChangeHandler,
            name: "usePercentValueTolerance",
            id: "usePercentValueTolerance",
          },
        },
        {
          id: "useChangeCarrierNfe",
          componentType: 'CHECKBOX',
          data: {
            title: "Permitir a troca da transportadora na NFE",
            value: auditConfigurations.useChangeCarrierNfe,
            onChange: this.auditChangeHandler,
            name: "useChangeCarrierNfe",
            id: "useChangeCarrierNfe",
          },
        },
        {
          id: "useFixedValueTolerance",
          componentType: 'CHECKBOX',
          data: {
            title: "Utilizar valor fixo de tolerância",
            value: auditConfigurations.useFixedValueTolerance,
            onChange: this.auditChangeHandler,
            name: "useFixedValueTolerance",
            id: "useFixedValueTolerance",
          }
        },
        {
          id: "usePercentageFreightAboutNFe",
          componentType: 'CHECKBOX',
          data: {
            title: "Utilizar percentual do Frete sobre NFe",
            value: auditConfigurations.usePercentageFreightAboutNFe,
            onChange: this.auditChangeHandler,
            name: "usePercentageFreightAboutNFe",
            id: "usePercentageFreightAboutNFe",
          }
        },
        {
          id: "useGroupDocumentsByInvoice",
          componentType: 'CHECKBOX',
          data: {
            title: "Agrupar documentos por fatura",
            value: auditConfigurations.useGroupDocumentsByInvoice,
            onChange: this.auditChangeHandler,
            name: "useGroupDocumentsByInvoice",
            id: "useGroupDocumentsByInvoice",
          }
        },
        {
          id: "extractWbsFromNFeInfoParams",
          componentType: 'CHECKBOX',
          data: {
            title: "Extrair WBS das inf. adicionais da NF",
            value: auditConfigurations.extractWbsFromNFeInfoParams,
            onChange: this.auditChangeHandler,
            name: "extractWbsFromNFeInfoParams",
            id: "extractWbsFromNFeInfoParams",
          }
        },
        {
          id: "useManualInvoiceRelease",
          componentType: 'CHECKBOX',
          data: {
            title: "Usar liberação de fatura manual",
            value: auditConfigurations.useManualInvoiceRelease,
            onChange: this.auditChangeHandler,
            name: "useManualInvoiceRelease",
            id: "useManualInvoiceRelease",
          }
        },
        {
          id: "blockCteNormalNfeInOtherCTE",
          componentType: 'CHECKBOX',
          data: {
            title: "Bloquear NFe CTE normal in outro CTE",
            value: auditConfigurations.blockCteNormalNfeInOtherCTE,
            onChange: this.auditChangeHandler,
            name: "blockCteNormalNfeInOtherCTE",
            id: "blockCteNormalNfeInOtherCTE",
          }
        },
        {
          id: "useCodeCfopCteBlockPurchase",
          componentType: 'CHECKBOX',
          data: {
            title: "Bloquear CTE de Compra com CFOP",
            value: auditConfigurations.useCodeCfopCteBlockPurchase,
            onChange: this.auditChangeHandler,
            name: "useCodeCfopCteBlockPurchase",
            id: "useCodeCfopCteBlockPurchase",
          }
        },
        {
          id: "enableDivergenceIndicator",
          componentType: 'CHECKBOX',
          data: {
            title: "Habilitar Indicador de Divergência ",
            value: auditConfigurations.enableDivergenceIndicator,
            onChange: this.auditChangeHandler,
            name: "enableDivergenceIndicator",
            id: "enableDivergenceIndicator",
          },
        },

      ],
      integrations: [
        {
          id: "useDeliveryScheduling",
          componentType: 'CHECKBOX',
          data: {
            title: "Aceitar Delivery de Agendamento",
            value: schedulingDelivery.useDeliveryScheduling,
            onChange: this.integrationsChangeHandler,
            name: "useDeliveryScheduling",
            id: "useDeliveryScheduling",
          },
        },
        {
          id: "canUpdateOrder",
          componentType: 'CHECKBOX',
          data: {
            title: "Permitir atualização de pedido de embarque",
            value: orderActionsConfigurations.canUpdate,
            onChange: this.integrationsChangeHandler,
            name: "canUpdate",
            id: "order.canUpdate",
          },
        }, {
          id: "canDeleteOrder",
          componentType: 'CHECKBOX',
          data: {
            title: "Permitir exclusão de pedido de embarque",
            value: orderActionsConfigurations.canDelete,
            onChange: this.integrationsChangeHandler,
            name: "canDelete",
            id: "order.canDelete",
          },
        },
        {
          id: "canUpdateShipment",
          componentType: 'CHECKBOX',
          data: {
            title: "Permitir atualização de embarque",
            value: shipmentActionsConfigurations.canUpdate,
            onChange: this.integrationsChangeHandler,
            name: "canUpdate",
            id: "shipment.canUpdate",
          },
        },
        {
          id: "canDeleteShipment",
          componentType: 'CHECKBOX',
          data: {
            title: "Permitir exclusão de enbarque",
            value: shipmentActionsConfigurations.canDelete,
            onChange: this.integrationsChangeHandler,
            name: "canDelete",
            id: "shipment.canDelete",
          },
        },
        {
          id: "onlyNFeEDI",
          componentType: 'CHECKBOX',
          data: {
            title: "Mostrar somente NFe com EDI",
            value: integrationConfigurations.onlyNFeWithEDI || false,
            onChange: this.integrationsChangeHandler,
            name: "onlyNFeWithEDI",
            id: "onlyNFeWithEDI",
          },
        },
        {
          id: "extractVendFromNFeInfo",
          componentType: 'CHECKBOX',
          data: {
            title: "Extrair Vendedor da NF",
            value: integrationConfigurations.extractVendedorFromNFeInfo,
            onChange: this.integrationsChangeHandler,
            name: "extractVendedorFromNFeInfo",
            id: "extractVendedorFromNFeInfo",
          },
        },
        {
          id: "nFeByUserPerfil",
          componentType: 'CHECKBOX',
          data: {
            title: "Filtrar NFe Por Perfil Logado",
            value: integrationConfigurations.filterNFeByUserPerfil,
            onChange: this.integrationsChangeHandler,
            name: "filterNFeByUserPerfil",
            id: "filterNFeByUserPerfil",
          },
        }
      ],
      ftpConfigurations: [,
        {
          id: "user",
          componentType: 'TEXT',
          data: {
            title: "Usuário",
            value: ftpConfiguration.user,
            onChange: this.integrationsChangeHandler,
            name: "user",
            id: "user",
          }
        },
        {
          id: "password",
          componentType: 'PASSWORD',
          data: {
            title: "Senha",
            value: ftpConfiguration.password,
            onChange: this.integrationsChangeHandler,
            name: "password",
            id: "password",
          },
        },
        {
          id: "host",
          componentType: 'TEXT',
          data: {
            title: "Host",
            value: ftpConfiguration.host,
            onChange: this.integrationsChangeHandler,
            name: "host",
            id: "host",
          },
        },
        {
          id: "port",
          componentType: 'TEXT',
          data: {
            title: "Porta",
            value: ftpConfiguration.port,
            onChange: this.integrationsChangeHandler,
            name: "port",
            id: "port",
          },
        }],
      anymarketConfigurations: [
        {
          id: "gumgaToken",
          componentType: 'TEXT',
          data: {
            title: "GumgaToken",
            value: integrationAnymarket.gumgaToken,
            onChange: this.integrationsAnymarketChangeHandler,
            name: "gumgaToken",
            id: "gumgaToken",
          }
        }
      ],
      roteirizador: [
        {
          id: "roteirizadorLognex",
          componentType: 'CHECKBOX',
          data: {
            title: "Usar roteirizador Lognex",
            value: roteirizador.lognex,
            onChange: this.integrationRouterChangeHandler,
            name: "lognex",
            id: "lognex",
          }
        },
        {
          id: "roteirizadorAgile",
          componentType: 'CHECKBOX',
          data: {
            title: "Usar roteirizador Ágile",
            value: roteirizador.agile,
            onChange: this.integrationRouterChangeHandler,
            name: "agile",
            id: "agile",
          }
        },
      ],
      deliveryDeadLineConfigurations: [
        {
          id: "before",
          componentType: 'TEXT',
          data: {
            title: "Dias antes da data de entrega",
            value: deliveryDeadLineConfigurations.before || 0,
            onChange: this.onDeliveryDeadlineChangeHandler,
            name: "before",
            id: "before",
          }
        },
        {
          id: "after",
          componentType: 'TEXT',
          data: {
            title: "Dias após a data de entrega",
            value: deliveryDeadLineConfigurations.after || 0,
            onChange: this.onDeliveryDeadlineChangeHandler,
            name: "after",
            id: "after",
          }
        },
      ],

      orderManagementConfigurations: [
        {
          id: "deliveryTime",
          componentType: 'TEXT',
          data: {
            title: "Tempo para entrega/coleta",
            value: orderManagementConfigurations.deliveryTime,
            onChange: this.orderManagementOnChange,
            name: "deliveryTime",
            id: "deliveryTime",
          }
        },
        {
          componentType: 'TIME',
          title: "Jornada de Trabalho Período Manhã",
          data: {
            start: {
              value: orderManagementConfigurations.workingDay.workingMorning.start,
              onChange: this.orderManagementOnChange,
              name: "workingDay.workingMorning.start",
              id: "workingDay.workingMorning.start",
              placeholder: "início"
            },
            end: {
              value: orderManagementConfigurations.workingDay.workingMorning.end,
              onChange: this.orderManagementOnChange,
              name: "workingDay.workingMorning.end",
              id: "workingDay.workingMorning.end",
              placeholder: "fim"
            }
          }
        },
        {
          componentType: 'TIME',
          title: "Jornada de Trabalho Período Tarde",
          data: {
            start: {
              value: orderManagementConfigurations.workingDay.workingAfternoon.start,
              onChange: this.orderManagementOnChange,
              name: "workingDay.workingAfternoon.start",
              id: "workingDay.workingAfternoon.start",
              placeholder: "início"
            },
            end: {
              value: orderManagementConfigurations.workingDay.workingAfternoon.end,
              onChange: this.orderManagementOnChange,
              name: "workingDay.workingAfternoon.end",
              id: "workingDay.workingAfternoon.end",
              placeholder: "fim"
            }
          }
        },
        {
          id: "titleWorkDays",
          componentType: 'TITLE',
          data: {
            title: "Dias de trabalho do motorista",
          }
        },
        {
          id: "workDays.monday",
          componentType: 'CHECKBOX',
          data: {
            title: "Segunda-feira",
            value: orderManagementConfigurations.workDays.monday,
            onChange: this.orderManagementOnChange,
            name: "workDays.monday",
            id: "workDays.monday",
          }
        },
        {
          id: "workDays.tuesday",
          componentType: 'CHECKBOX',
          data: {
            title: "Terça-feira",
            value: orderManagementConfigurations.workDays.tuesday,
            onChange: this.orderManagementOnChange,
            name: "workDays.tuesday",
            id: "workDays.tuesday",
          }
        },
        {
          id: "workDays.wednesday",
          componentType: 'CHECKBOX',
          data: {
            title: "Quarta-feira",
            value: orderManagementConfigurations.workDays.wednesday,
            onChange: this.orderManagementOnChange,
            name: "workDays.wednesday",
            id: "workDays.wednesday",
          }
        },
        {
          id: "workDays.thursday",
          componentType: 'CHECKBOX',
          data: {
            title: "Quinta-feira",
            value: orderManagementConfigurations.workDays.thursday,
            onChange: this.orderManagementOnChange,
            name: "workDays.thursday",
            id: "workDays.thursday",
          }
        },
        {
          id: "workDays.friday",
          componentType: 'CHECKBOX',
          data: {
            title: "Sexta-feira",
            value: orderManagementConfigurations.workDays.friday,
            onChange: this.orderManagementOnChange,
            name: "workDays.friday",
            id: "workDays.friday",
          }
        },
        {
          id: "workDays.saturday",
          componentType: 'CHECKBOX',
          data: {
            title: "Sábado",
            value: orderManagementConfigurations.workDays.saturday,
            onChange: this.orderManagementOnChange,
            name: "workDays.saturday",
            id: "workDays.saturday",
          }
        },
        {
          id: "workDays.sunday",
          componentType: 'CHECKBOX',
          data: {
            title: "Domingo",
            value: orderManagementConfigurations.workDays.sunday,
            onChange: this.orderManagementOnChange,
            name: "workDays.sunday",
            id: "workDays.sunday",
          }
        },
      ],

    }

    if (freightCalcConfigurations.extractCubageFromNFeInfoParams) {

      configurations.freightCalcConfigurations.push(
        {
          id: "cubagePattern",
          componentType: 'INPUT',
          data: {
            title: "Padrão do texto de cubagem",
            value: freightCalcConfigurations.cubagePattern,
            onChange: this.freightCalculationChangeHandler,
            name: "cubagePattern",
            id: "cubagePattern",
          },
        },
      )
    }
    if (freightCalcConfigurations.extractVehicleTypeFromNFeInfoParams) {

      configurations.freightCalcConfigurations.push(
        {
          id: "vehicleTypePattern",
          componentType: 'INPUT',
          data: {
            title: "Padrão do texto do tipo veículo",
            value: freightCalcConfigurations.vehicleTypePattern,
            onChange: this.freightCalculationChangeHandler,
            name: "vehicleTypePattern",
            id: "vehicleTypePattern",
          },
        },
      )
    }
    if (freightCalcConfigurations.usarTagFiltrarTabelaFrete) {

      configurations.freightCalcConfigurations.push(
        {
          id: "tagFiltrarTabelaEmbarque",
          componentType: 'INPUT',
          data: {
            title: "Tag Filtrar Tabela - Embarque",
            value: freightCalcConfigurations.tagFiltrarTabelaEmbarque,
            onChange: this.freightCalculationChangeHandler,
            name: "tagFiltrarTabelaEmbarque",
            id: "tagFiltrarTabelaEmbarque",
          },
        },
      )
    }


    if (auditConfigurations.usePercentValueTolerance) {
      configurations.auditConfigurations.push({
        id: "percentToleranceValue",
        componentType: 'INPUT',
        data: {
          title: "Percentual de tolerância",
          value: auditConfigurations.percentToleranceValue,
          onChange: this.auditChangeHandler,
          name: "percentToleranceValue",
          id: "percentToleranceValue",
          disabled: !auditConfigurations.usePercentValueTolerance,
          currencyCode: "%"
        }
      })
    }

    if (auditConfigurations.useFixedValueTolerance) {
      configurations.auditConfigurations.push({
        id: "toleranceValue",
        componentType: 'INPUT',
        data: {
          title: "Valor de tolerância",
          value: auditConfigurations.toleranceValue,
          onChange: this.auditChangeHandler,
          name: "toleranceValue",
          id: "toleranceValue",
          disabled: !auditConfigurations.useFixedValueTolerance,
          currencyCode: "R$"
        }
      })
    }

    if (auditConfigurations.usePercentageFreightAboutNFe) {
      configurations.auditConfigurations.push({
        id: "percentFreightAboutNFeValue",
        componentType: 'INPUT',
        data: {
          title: "Percentual do Frete Sobre a NFe",
          value: auditConfigurations.percentFreightAboutNFeValue,
          onChange: this.auditChangeHandler,
          name: "percentFreightAboutNFeValue",
          id: "percentFreightAboutNFeValue",
          disabled: !auditConfigurations.usePercentageFreightAboutNFe,
          currencyCode: "%"
        }
      });
    }


    configurations.favoriteConfigurations.favorites = favoriteConfigurations.favorites.map((favorite, idx) => {
      return (<div className="p-col-12" key={idx}>
        <Checkbox
          inputId="cb1"
          value={favorite.oid}
          onChange={this.onFavoriteChangeHandler}
          checked={favorite.selected}
          name="favorites"
          id="favorites"
        ></Checkbox>
        <label htmlFor="cb1" className="p-checkbox-label">
          {favorite.title}
        </label>
      </div>)
    })



    let dialogConfirmReprocess = null;
    if (this.hasAuditChanges() || this.hasFreightCalculationChanges()) {
      dialogConfirmReprocess = {
        header: "Reprocessar",
        modal: false,
        message: "Deseja reprocessar os registros atualmente abertos?",
      }
    }
    const header = (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h1>Configurações</h1>
        </div>
        <hr className="lxSplitter"></hr>
        <div>
          <Toolbar
            onSave={this.saveAccountConfigurations}
            saveButtonStyle="highlighted"
            saveDialog={dialogConfirmReprocess}
          ></Toolbar>
        </div>
      </div>
    );

    const content = (
      <div className="p-grid">
        <div className="p-col-2">
          <Menu model={menuItems} />
        </div>

        <div className="p-col-10">
          <AccountConfigurationForm
            configurations={configurations}
          />
        </div>
      </div>
    );

    return <Page header={header} content={content} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountConfigurationFormPageContainer);
